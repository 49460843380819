import Animate from 'components/atoms/animate'
import Icon from 'components/atoms/icon'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useResourceTextData } from 'hooks/useResourceTextData'
import Ig from 'images/svg/ig.svg'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

const StyledInstagram = styled.div`
  ${({ theme }): CSSProp => css`
    margin-top: 5rem;
    .join {
      text-align: center;
      font-weight: ${theme.font.fontWeightLight};
      margin-bottom: 1rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: ${theme.font.fontSizeBodyDefault};
      }
      .icon {
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: .5rem;
      }
    }
    .items {
      display: grid;
      @media only screen and ${theme.breakpoints.toMediumScreen} {
        grid-gap: 0.4rem;
        padding: 0.4rem;
        grid-template-columns: repeat(4, 1fr);
        a:nth-child(n + 9) {
          display: none;
        }
      }
      @media only screen and ${theme.breakpoints.fromMediumScreen} and ${theme.breakpoints.toNormalScreen} {
        grid-gap: 0.9rem;
        padding: 0.9rem;
        grid-template-columns: repeat(7, 1fr);
        a:nth-child(n + 8) {
          display: none;
        }
      }
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        grid-gap: 0.9rem;
        padding: 0.9rem;
        grid-template-columns: repeat(10, 1fr);
      }
      a,
      img {
        display: block;
      }
    }
  `}
`

const Instagram = (): JSX.Element => {
  const { allInstagramPost } = useStaticQuery(
    graphql`
      query {
        allInstagramPost {
          nodes {
            shortcode
            thumbnail_resources {
              src
            }
          }
        }
      }
    `
  )

  return (
    <Animate>
      <StyledInstagram>
        <div className="join">
          <Icon className="icon">
            <Ig />
          </Icon>{' '}
          {useResourceTextData('home.instagramuserjoinUs', 'Join us')}{' '}
          <a
            href={useResourceTextData('footer.instagramLink')}
            rel="noopener noreferrer"
            target="_blank"
          >
            @{useResourceTextData('home.instagramuser', 'allpressespresso')}
          </a>
        </div>
        <div className="items">
          {allInstagramPost?.nodes?.map(item => {
            return item.shortcode ? (
              <a
                key={item.shortcode}
                href={`https://www.instagram.com/p/${item.shortcode}/`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Img
                  fluid={{
                    src: item.thumbnail_resources?.[4]?.src,
                    srcSet: '',
                    sizes: '',
                    aspectRatio: 1,
                  }}
                />
              </a>
            ) : null
          })}
        </div>
      </StyledInstagram>
    </Animate>
  )
}

export default Instagram
