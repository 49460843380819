import Layout from 'components/layout'
import Panel from 'components/molecules/panel'
import Seo from 'components/molecules/seo'
import Carousel from 'components/organisms/carousel'
import CTABlock from 'components/organisms/ctaBlock'
import Hero from 'components/organisms/hero'
import Instagram from 'components/organisms/instagram'
import Panels from 'components/organisms/panels'
import { graphql, PageProps } from 'gatsby'
import { getLocalNodes, getRegionalLink } from 'helpers/locale'
import { useResourceMediaData } from 'hooks/useResourceMediaData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import styled from 'styled-components'
import {
  ContentfulArticleConnection,
  ContentfulCtaBlockConnection,
  ContentfulHeroPanelConnection,
  ContentfulProductConnection,
  ContentfulRegionalLinkConnection,
  Site,
} from 'types/graphql-types'

const CarouselWrapper = styled.div`
  margin: 3rem 0;
  padding: 2rem 0;
`

type IndexTypes = PageProps & {
  data: {
    site: Site
    allContentfulHeroPanel: ContentfulHeroPanelConnection
    allContentfulArticle: ContentfulArticleConnection
    allContentfulCtaBlock: ContentfulCtaBlockConnection
    allContentfulProduct: ContentfulProductConnection
    productRegionalLink: ContentfulRegionalLinkConnection
    wholesaleCoffee: ContentfulCtaBlockConnection
  }
}

const RootIndex = ({ data, ...props }: IndexTypes): JSX.Element => {
  const heroData = getLocalNodes(data.allContentfulHeroPanel.nodes)
  const productData = getLocalNodes(data.allContentfulProduct.nodes)
  const productRegionalLinkData = getLocalNodes(data.productRegionalLink.nodes)
  const ctaData = getLocalNodes(data.allContentfulCtaBlock.nodes)
  const articlesData = getLocalNodes(data.allContentfulArticle.nodes)
  const wholesaleCoffeeData = getLocalNodes(data.wholesaleCoffee.nodes)

  const {
    body: { body: ctaBody },
    cta,
  } = ctaData[0]

  const {
    body: { body: wholesaleCoffeeBody },
    cta: wholesaleCoffeeCTA,
    images: wholesaleCoffeeImages,
  } = wholesaleCoffeeData[0]
  return (
    <Layout header="trans">
      <Seo
        title="Designed for flavour"
        url={props.location.href}
        imageUrl={heroData[0].image.fluid.src.replace(/^\/\//, 'https://')}
      />

      <Hero {...heroData[0]} image={heroData[0].image.fluid} />
      <CarouselWrapper>
        <Carousel
          intro={useResourceTextData('home.productsintro')}
          introCta={{
            text: useResourceTextData('home.productsbrowse', 'Browse products'),
            to: getRegionalLink(productRegionalLinkData[0]),
          }}
          items={productData}
          type="card"
        />
      </CarouselWrapper>
      <Panels>
        <Panel
          title={useResourceTextData('home.findacafetitle', 'Find a cafe')}
          body={useResourceTextData('home.findacafebody')}
          {...useResourceMediaData('home.findacafeimage')}
          to={useResourceTextData('home.findacafelink', '/find')}
        />
        <Panel
          title={useResourceTextData(
            'home.findaroasterytitle',
            'Find a roastery'
          )}
          body={useResourceTextData('home.findaroasterybody')}
          {...useResourceMediaData('home.findaroasteryimage')}
          to={useResourceTextData('home.findaroasterylink', '/find/roastery')}
        />
      </Panels>
      <CTABlock body={ctaBody} cta={cta} />
      <CarouselWrapper>
        <Carousel
          intro={useResourceTextData('home.latestarticlesintro')}
          introCta={{
            text: useResourceTextData(
              'home.latestarticlesbrowse',
              'Browse articles'
            ),
            to: '/community',
          }}
          items={articlesData}
        />
      </CarouselWrapper>
      <CTABlock
        body={wholesaleCoffeeBody}
        cta={wholesaleCoffeeCTA}
        images={wholesaleCoffeeImages}
      />
      <Instagram />
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        ogDescription
      }
    }
    allContentfulHeroPanel(filter: { key: { eq: "home.hero" } }) {
      nodes {
        id
        title
        image {
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        body {
          body
        }
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        node_locale
      }
    }
    productRegionalLink: allContentfulRegionalLink(
      filter: { key: { eq: "regionalLink.onlineStore" } }
    ) {
      nodes {
        title
        ukSlug
        nzSlug
        jpSlug
        auSlug
        node_locale
        sgSlug
      }
    }
    allContentfulProduct(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        id
        title
        link {
          auSlug
          nzSlug
          sgSlug
          ukSlug
        }
        node_locale
        subtitle
        image {
          title
          fluid {
            ...Image
          }
        }
      }
    }
    allContentfulCtaBlock(filter: { key: { eq: "home.pullquote" } }) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        node_locale
      }
    }
    allContentfulArticle(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        id
        slug
        heroImage {
          fluid {
            ...Image
          }
          title
        }
        node_locale
        title
        category {
          title
          slug
        }
      }
    }
    wholesaleCoffee: allContentfulCtaBlock(
      filter: { key: { eq: "home.wholesale-and-coffee" } }
    ) {
      nodes {
        id
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        body {
          body
        }
        images {
          media {
            title
            fluid {
              ...Image
            }
          }
        }
        node_locale
      }
    }
  }
`
